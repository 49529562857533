<template>
  <div>
    <!-- page Title-->
    <v-sheet v-if="!noTitle" class="d-flex justify-center align-center py-16 section_first"  >
      <v-sheet :class="!SM ? 'd-flex' : ''" class=" align-center justify-space-between"  color=" transparent px-4 pt-9" min-height="200"  :width="width">

        <div>
          <slot name="title_content">
            <h1 :style="`font-size : ${SM ? '18px' : ''}`"
                :class="[{'text-center' : SM}]"
                class="mt-8"
            >
              {{ title }}
            </h1>

            <div>
              <h4 class="font-weight-medium  mt-3" :class="[{'text-center' : SM}]" style="color: #7a7a7a ; max-width: 600px" >
                {{ subtitle }}
              </h4>
            </div>
          </slot>
          <v-btn
              v-if="displayTryNow"
              @click="$store.state.homepage.displayRegisterBusiness = true"
              :color="wsACCENT"
              :block="SM"
              class="noCaps mt-8 px-6"
              elevation="0"
              large
              dark>
            {{ $t('TryNow') }}
          </v-btn>
          <v-btn
              v-if="displayContactForm"
              @click="$store.state.homepage.displayContactForm = true"
              :color="wsACCENT"
              :block="SM"
              class="noCaps mt-8 px-6"
              elevation="0"
              large
              dark>
            {{ $t('homepage.sections.consultation.order') }}
          </v-btn>

          <slot name="title.append"></slot>
        </div>
        <div>
          <slot name="title.append_right"></slot>
        </div>


      </v-sheet>
    </v-sheet>

   <slot name="default">
     <v-sheet class="d-flex justify-center align-center py-16 "  >
       <v-sheet color="transparent px-4 " min-height="200"  :width="width">
         <slot name="first_section"></slot>
       </v-sheet>
     </v-sheet>
   </slot>





  </div>

</template>

<script>
export default {
  name: "pagePublic",
  props : {
    title : {
      type : String,
      default : ''
    },
    subtitle : {
      type : String,
      default : ''
    },
    width : {
      type : String,
      default : '1140'
    },
    displayTryNow : {
      type : Boolean,
      default : false
    },
    displayContactForm : {
      type : Boolean,
      default : false
    },
    noTitle : {
      type : Boolean,
      default : false
    }


  }
}
</script>

<style scoped>
h4 {
  font-size: 14px !important;
}

.section_first {
  background-image: url('~@/assets/img/bg-pages.jpg');
  background-position: center center;
  position: relative;
  background-size: cover;
}
</style>